/*!

 =========================================================
 * Black Dashboard Pro - v1.1.1
 =========================================================

 * Product Page: https://themes.getbootstrap.com/product/black-dashboard-pro-premium-bootstrap-4-admin/
 * Copyright 2022 Creative Tim (http://www.creative-tim.com)


 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// Core

@import 'black-dashboard-pro-react/custom/functions';
@import '../../../node_modules/bootstrap/scss/functions';

@import 'black-dashboard-pro-react/custom/variables';
@import '../../../node_modules/bootstrap/scss/variables';

@import 'black-dashboard-pro-react/custom/mixins';
@import '../../../node_modules/bootstrap/scss/mixins';

// Bootstrap components

@import '../../../node_modules/bootstrap/scss/root';
@import '../../../node_modules/bootstrap/scss/reboot';
@import '../../../node_modules/bootstrap/scss/type';
@import '../../../node_modules/bootstrap/scss/images';
@import '../../../node_modules/bootstrap/scss/code';
@import '../../../node_modules/bootstrap/scss/grid';
@import '../../../node_modules/bootstrap/scss/tables';
@import '../../../node_modules/bootstrap/scss/forms';
@import '../../../node_modules/bootstrap/scss/buttons';
@import '../../../node_modules/bootstrap/scss/transitions';
@import '../../../node_modules/bootstrap/scss/dropdown';
@import '../../../node_modules/bootstrap/scss/button-group';
@import '../../../node_modules/bootstrap/scss/input-group';
@import '../../../node_modules/bootstrap/scss/custom-forms';
@import '../../../node_modules/bootstrap/scss/nav';
@import '../../../node_modules/bootstrap/scss/navbar';
@import '../../../node_modules/bootstrap/scss/card';
@import '../../../node_modules/bootstrap/scss/breadcrumb';
@import '../../../node_modules/bootstrap/scss/pagination';
@import '../../../node_modules/bootstrap/scss/badge';
@import '../../../node_modules/bootstrap/scss/jumbotron';
@import '../../../node_modules/bootstrap/scss/alert';
@import '../../../node_modules/bootstrap/scss/progress';
@import '../../../node_modules/bootstrap/scss/media';
@import '../../../node_modules/bootstrap/scss/list-group';
@import '../../../node_modules/bootstrap/scss/close';
@import '../../../node_modules/bootstrap/scss/modal';
@import '../../../node_modules/bootstrap/scss/tooltip';
@import '../../../node_modules/bootstrap/scss/popover';
@import '../../../node_modules/bootstrap/scss/carousel';
@import '../../../node_modules/bootstrap/scss/utilities';
@import '../../../node_modules/bootstrap/scss/print';

// Custom components

@import 'black-dashboard-pro-react/custom/reboot.scss';
@import 'black-dashboard-pro-react/custom/global.scss';
@import 'black-dashboard-pro-react/custom/utilities.scss';
@import 'black-dashboard-pro-react/custom/alerts.scss';
@import 'black-dashboard-pro-react/custom/avatars.scss';
@import 'black-dashboard-pro-react/custom/badge.scss';
@import 'black-dashboard-pro-react/custom/buttons.scss';
@import 'black-dashboard-pro-react/custom/close.scss';
@import 'black-dashboard-pro-react/custom/custom-forms.scss';
@import 'black-dashboard-pro-react/custom/dropdown.scss';
@import 'black-dashboard-pro-react/custom/footer.scss';
@import 'black-dashboard-pro-react/custom/forms.scss';
@import 'black-dashboard-pro-react/custom/grid.scss';
@import 'black-dashboard-pro-react/custom/icons.scss';
@import 'black-dashboard-pro-react/custom/images.scss';
@import 'black-dashboard-pro-react/custom/input-group.scss';
@import 'black-dashboard-pro-react/custom/info-areas.scss';
@import 'black-dashboard-pro-react/custom/modal.scss';
@import 'black-dashboard-pro-react/custom/nav.scss';
@import 'black-dashboard-pro-react/custom/navbar.scss';
@import 'black-dashboard-pro-react/custom/pagination.scss';
@import 'black-dashboard-pro-react/custom/tabs.scss';
@import 'black-dashboard-pro-react/custom/pills.scss';
@import 'black-dashboard-pro-react/custom/popover.scss';
@import 'black-dashboard-pro-react/custom/progress.scss';
@import 'black-dashboard-pro-react/custom/type.scss';
@import 'black-dashboard-pro-react/custom/tables';
@import 'black-dashboard-pro-react/custom/checkboxes-radio';
@import 'black-dashboard-pro-react/custom/fixed-plugin';
@import 'black-dashboard-pro-react/custom/sidebar-and-main-panel.scss';
@import 'black-dashboard-pro-react/custom/misc.scss';
@import 'black-dashboard-pro-react/custom/rtl.scss';
@import 'black-dashboard-pro-react/custom/timeline.scss';

// Vendor / Plugins

@import 'black-dashboard-pro-react/custom/vendor/plugin-datetimepicker.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-nouislider.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-fullcalendar.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-sweetalert2.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-bootstrap-switch.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-bootstrap-select.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-jasny-fileupload.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-tagsinput.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-perfect-scrollbar.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-jquery.jvectormap.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-datatables.net.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-animate-bootstrap-notify.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-card-wizard.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-chartjs.scss';

// example pages and sections

@import 'black-dashboard-pro-react/custom/example-pages.scss';

// light mode

@import 'black-dashboard-pro-react/custom/white-content.scss';

// Cards

@import 'black-dashboard-pro-react/custom/card';
@import 'black-dashboard-pro-react/custom/cards/card-chart';
@import 'black-dashboard-pro-react/custom/cards/card-map';
@import 'black-dashboard-pro-react/custom/cards/card-user';
@import 'black-dashboard-pro-react/custom/cards/card-task';
@import 'black-dashboard-pro-react/custom/cards/card-animation-on-hover';
@import 'black-dashboard-pro-react/custom/cards/card-collapse';
@import 'black-dashboard-pro-react/custom/cards/card-contributions';
@import 'black-dashboard-pro-react/custom/cards/card-lock';
@import 'black-dashboard-pro-react/custom/cards/card-pricing';
@import 'black-dashboard-pro-react/custom/cards/card-profile';
@import 'black-dashboard-pro-react/custom/cards/card-plain';
@import 'black-dashboard-pro-react/custom/cards/card-register';
@import 'black-dashboard-pro-react/custom/cards/card-stats';
@import 'black-dashboard-pro-react/custom/cards/card-subcategories';
@import 'black-dashboard-pro-react/custom/cards/card-testimonials';
@import 'black-dashboard-pro-react/custom/cards/card-wizard';

// React Differences
@import 'black-dashboard-pro-react/react/react-differences';
[data-amplify-authenticator][data-variation='modal'] {
  background-image: url('../img/HorseRacing.jpg');
  background-position: center;
  background-size: cover;
}
